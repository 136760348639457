import { IllustrationForWizardPage1, QRCode } from '@/components/Icon';
import React, { useCallback, useState } from 'react';
import Scanner from '@/components/Scanner';

interface Props {
  guardId: string;
  onGuardIdChange: (guardId: string) => void;
  onNext: (id: string) => void;
  onCancel: () => void;
}

const StepSelectGuard: React.FC<Props> = ({
  guardId,
  onGuardIdChange,
  onNext,
  onCancel,
}) => {
  const [showScanner, setShowScanner] = useState(false);

  const handleScan = useCallback(
    (value: string) => {
      onGuardIdChange(value);
      setShowScanner(false);
    },
    [onGuardIdChange],
  );

  if (showScanner) {
    return (
      <Scanner onCancel={() => setShowScanner(false)} onScan={handleScan} />
    );
  }

  return (
    <>
      <div className="flex grow flex-col gap-4 px-5 py-4">
        <IllustrationForWizardPage1 className="h-48 w-full" />
        <h2 className="text-center text-lg font-bold">Enter Guard ID</h2>
        <input
          type="text"
          className="input"
          value={guardId}
          onChange={(e) => onGuardIdChange(e.target.value)}
          placeholder="Enter guard ID"
        />
        <button className="button" onClick={() => setShowScanner(true)}>
          <QRCode />
          <span>Scan</span>
        </button>
      </div>
      <div className="flex flex-col gap-4 p-4">
        <button
          type="button"
          onClick={() => onNext(guardId)}
          className="button justify-center py-4"
          disabled={!guardId}
        >
          Next
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="outline-button"
          disabled={false}
        >
          Cancel installation
        </button>
      </div>
    </>
  );
};

export default StepSelectGuard;
