import React from 'react';

export const QRCode: React.FC = () => (
  <svg width="50px" height="50px" viewBox="0 0 50 50">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#FFFFFF">
        <g>
          <path
            d="M5,7 L5,18 L16,18 L16,7 L5,7 Z M13.1797495,15.1797495 L7.82073191,15.1797495 L7.82073191,9.82073191 L13.1797495,9.82073191 L13.1797495,15.1797495 Z"
            fillRule="nonzero"
          />
          <path
            d="M34,7 L34,18 L45,18 L45,7 L34,7 Z M42.1797495,15.1797495 L36.8207319,15.1797495 L36.8207319,9.82073191 L42.1797495,9.82073191 L42.1797495,15.1797495 Z"
            fillRule="nonzero"
          />
          <path
            d="M5,32 L5,43 L16,43 L16,32 L5,32 Z M13.1797495,40.2736174 L7.82073191,40.2736174 L7.82073191,34.9145999 L13.1797495,34.9145999 L13.1797495,40.2736174 Z"
            fillRule="nonzero"
          />
          <path
            d="M22,36.9783443 C22,36.2593029 22.0898876,35.5402615 22,34.82122 C22,34.19203 22.1797753,34.0123272 22.80896,34.0123272 C23.5280611,34.1022073 24.2471622,34.0123272 25.1460387,34.0123272 L25.1460387,30.5968803 C25.1460387,29.7879875 24.8763757,28.7094253 25.3258139,28.2599957 C25.7752521,27.810566 26.9437915,28.0802353 27.752809,28.0802353 L28.2921348,28.0802353 L28.2921348,31.1361614 L34.7640449,31.1361614 L34.7640449,34.1021786 L38,34.1021786 L38,37.0681958 L34.9438202,37.0681958 L34.9438202,43 L31.797724,43 L31.797724,40.0339828 L25.3258139,40.0339828 L25.3258139,36.9780567 L22,36.9780567 L22,36.9783443 Z M31.5278022,36.9783443 L31.5278022,34.0123272 L29.0109483,34.0123272 C28.831173,34.0123272 28.4716225,34.3718479 28.4716225,34.5516082 C28.3817348,35.3605011 28.4716225,36.1694515 28.4716225,36.9784019 L31.5278022,36.9784019 L31.5278022,36.9783443 Z"
            fillRule="nonzero"
          />
          <path d="M28,28 C28.0001164,27.2726494 28.0910255,26.6362649 28.0001164,25.9090016 C28.0001164,25.1817383 28.1819345,24.9089854 28.9092073,24.9999224 C29.63648,25.0908594 30.3637527,24.9999224 31.1819055,24.9999224 L31.1819055,21.3636058 C31.1819055,19 31.1819055,19 33.72736,19 L42.9090327,19 L44,19 L43.9999418,21.9999321 L41.6363055,21.9999321 C41.0908509,21.9999321 40.8181527,22.09084 40.8181527,22.6363166 L40.8181527,24.9999224 L44,24.9999224 L44,27.9998545 L37.5453091,27.9998545 L37.5453091,22.000223 L34.2725818,22.000223 L34.2725818,27.9090921 C32.1816436,28 30.0907636,28 28,28 L28,28 Z" />
          <path d="M21.23247,19 L21.23247,16.0225638 L18.1916647,16.0225638 C18.0995182,15.9323376 18.0995182,15.8421113 18.0995182,15.8421113 C18.0995182,15.0301043 17.8230787,13.9473897 18.1916647,13.4059747 C18.5602508,12.9548436 19.8503019,13.2255222 20.67965,13.135296 C20.863943,13.135296 21.048236,13.135296 21.324705,13.0450698 L21.324705,10.8796406 C21.324705,10.2480283 21.508998,10.0676336 22.1539941,10.0676336 L27.8670777,10.0676336 L27.8670777,7.81200706 C27.8670777,7.27064977 28.0513707,7 28.6963668,7 L31,7 L31,12.9546415 L24.5494495,12.9546415 L24.5494495,18.909283 C23.3515744,18.9995092 22.3379333,18.9995092 21.2321752,18.9995092 L21.23247,19 Z" />
          <path d="M38.000027,33.9090627 C38.000027,33.0909179 38.000027,32.3636522 38.0845337,31.5454493 C38.0845337,31.3636328 38.4225603,31 38.5915737,31 L44,31 L44,40.0000582 L41.0422398,40.0000582 L41.0422398,43 L38.0844796,43 L38.0844796,37.6363866 C38.0844796,37.4545701 38.4225063,37.1818455 38.6760533,37.1818455 C39.4365862,37.0909373 40.1971732,37.1818455 41.0422398,37.1818455 L41.0422398,34.0909664 C40.0281599,33.8182418 39.0140799,33.8182418 38,33.90915 L38,33.90915 L38.000027,33.9090627 Z" />
          <path d="M15,27.9998848 C15.0909077,27.0998963 15.0909077,26.1999078 15.1818153,25.1199217 L21.6364051,25.1199217 L21.6364051,22.7799516 C21.6364051,22.2399585 21.8182205,22.0599608 22.3636665,22.0599608 L24.8182026,22.0599608 L24.8182026,19.1799977 C25.9090946,19.0899988 26.9999866,19.0899988 28,19 L28,25.0300668 L24.8182026,25.0300668 L24.8182026,28 L15.0001745,28 L15,27.9998848 Z" />
          <path d="M9.00014545,24.9999713 C9.00014545,24.2835569 9.09105434,23.6566655 9.09105434,22.940251 C9.00014545,22.2238365 9.363781,22.0447329 10.0910812,22.0447329 C10.8183523,22.1342847 11.4547436,22.0447329 12.3637743,22.0447329 L12.3637743,19.8954895 C12.2728654,19.2685981 12.5455921,18.9999713 13.2728632,19.0895518 L20.9999731,19.0895518 C21.3636087,19.0895518 21.6363645,19 22,19 L22,22.0447616 L16.6363465,22.0447616 C15.8181957,22.0447616 15.4545601,22.2238652 15.5454399,23.0298602 C15.6363488,23.6567515 15.5454399,24.2835855 15.5454399,25 C13.3636266,24.9104482 11.1818133,24.9104482 9,25 L9.00014545,24.9999713 Z" />
          <path d="M25,39.9999855 L25,42.5454523 C25,42.6363619 25,42.8181809 24.9154968,43 L19,43 L19,37.4545477 C19,37.3636381 19.3380129,37.0909095 19.5915497,37.0909095 C20.3520517,37 21.1126079,37 21.9576402,37 L21.9576402,40.0909241 C23.1406855,40.0000145 24.070194,40.0000145 24.9997566,40.0000145 L25,39.9999855 Z" />
          <path d="M9,25 L9,31 L6,31 L6,27.149317 C6,25.1792148 6,25.1792148 8.11764706,25.1792148 C8.38235294,25.1792148 8.73532235,25.0896647 9,25.0001146 L9,25.0001146 L9,25 Z" />
          <path d="M22,7.02325581 L22,10 L19,10 L19,7.58139535 C19,7.39534884 19.3529412,7.11627907 19.5294118,7.11627907 C20.3235012,6.93023256 21.1176471,7.02325581 22,7.02325581 Z" />
          <path d="M28,19 C28.0000274,18.2726982 28.0857409,17.5454255 28.0857409,16.7272727 C28.0857409,16.1818182 28.2571679,16 28.7714489,16 L31,16 L31,18.9090909 C30.057124,19 29.028562,19 28,19 L28,19 Z" />
          <path d="M9,22 L6,22 L6,19.5958904 C6,19.4109589 6.35294118,19.1335616 6.52941176,19.0410959 C7.32350118,18.9486301 8.11764706,19.0410959 9,19.0410959 L9,22 Z" />
          <path d="M14.9617834,28.0000291 L14.9617834,31 L12.0382166,31 C12.0382166,30.1818473 11.9522293,29.3636364 12.0382166,28.6363636 C12.0382166,28.4545455 12.4681529,28.1818182 12.7261146,28.1818182 C13.4999725,28 14.2738854,28 14.9617834,28 C15.0477707,28 14.9617834,28 14.9617834,28 L14.9617834,28.0000291 Z" />
          <path d="M38,28.0000582 L38,31 L35,31 C35,30.1818552 35.0833333,29.4545895 35,28.6363866 C35,28.1818455 35.1666667,28 35.6666667,28 C36.5,28.0909082 37.3333333,28.0909082 38,28 L38,28.0000582 Z" />
          <path d="M22,34 L19,34 L19,31.5034197 C19,31.31849 19.3529412,31.0410955 19.5294118,31.0410955 C20.3235012,30.9486306 21.1176471,31.0410955 22,31.0410955 L22,34 L22,34 Z" />
          <polygon
            opacity="0.300000012"
            points="50 32 50 50 34 50 34 47.0000776 46.9480966 47.0000776 46.9480966 32.0004654"
          />
          <polygon
            opacity="0.300000012"
            points="17 46.9847763 17 50 0 50 0 32 3.06551724 32 3.06551724 46.9848933"
          />
          <polygon
            opacity="0.300000012"
            points="17 0 17 3.01520409 3.04886227 3.01520409 3.04886227 18 0 18 0 0.000116954505"
          />
          <polygon
            opacity="0.300000012"
            points="50 0 50 18 46.9511377 18 46.9511377 3.00038787 33 3.00038787 33 0.000465447023"
          />
        </g>
      </g>
    </g>
  </svg>
);
