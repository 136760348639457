import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Scanner from '@/components/Scanner';
import { QRCode, RoxtecLogo, TransitIcon } from '@/components/Icon';
import Navbar from '@/components/Navbar';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Search from '@/components/Search';
import { NavTarget } from '@/routes';
import { useTransits } from '@/api/useTransits';
import Fuse from 'fuse.js';
import List from '@/components/List/List';
import ListItem from '@/components/List/ListItem';
import { classNames } from '@/classNames';

const TransitFinder: React.FC = () => {
  const [showScanner, setShowScanner] = useState(false);
  const [search, setSearch] = useState('');
  const { data: transits, isLoading, isFetched } = useTransits();

  const fuse = useMemo(
    () =>
      new Fuse(transits ?? [], {
        keys: ['name', 'tag_serial'],
      }),
    [transits],
  );

  useEffect(() => {
    if (!transits) return;
    fuse.setCollection(transits);
  }, [transits, fuse]);

  const searchResults = useMemo(
    () =>
      fuse
        .search(search, {
          limit: 5,
        })
        .map((item) => item.item),
    [fuse, search],
  );

  const handleScan = useCallback((value: string) => {
    setSearch(value);
    setShowScanner(false);
  }, []);

  if (showScanner) {
    return (
      <Scanner onCancel={() => setShowScanner(false)} onScan={handleScan} />
    );
  }

  return (
    <div className="flex grow flex-col overflow-hidden">
      <Navbar icon={<RoxtecLogo />} />
      <div className="flex flex-col gap-4 bg-white p-4">
        <h1 className="header1">Quick search transit</h1>
        <Search value={search} onChange={setSearch} loading={isLoading} />
        <button className="button" onClick={() => setShowScanner(true)}>
          <QRCode />
          <span>Scan</span>
        </button>
      </div>
      <div className={classNames('grow overflow-auto', !search && 'bg-white')}>
        {search && (
          <List isLoading={isLoading} isFetched={isFetched}>
            {searchResults.map((transit) => (
              <Link
                key={transit.id}
                to={NavTarget.CustomerAssetTransit(
                  transit.customer_id,
                  transit.asset_id,
                  transit.id,
                )}
              >
                <ListItem
                  title={transit.name}
                  subtitle={transit.location}
                  startIcon={<TransitIcon className="h-6 w-6" />}
                  endIcon={<MdKeyboardArrowRight className="h-8 w-8" />}
                />
              </Link>
            ))}
          </List>
        )}
      </div>
      <div className="h-full max-h-72 flex-1 bg-roxtec">
        <Link
          to={NavTarget.Customers}
          className="button w-full bg-roxtec-600 py-6"
        >
          <div className="flex grow flex-col items-start gap-2">
            <span className="text-2xl">Browse Registry</span>
            <span className="text-gray-300">Customer | Asset | Transit</span>
          </div>
          <MdKeyboardArrowRight className="h-full w-16" />
        </Link>
      </div>
    </div>
  );
};

export default TransitFinder;
