import { MdClose, MdSort } from 'react-icons/md';
import { Fragment, useCallback, useState } from 'react';
import { Transition } from '@headlessui/react';
import RadioOptions from '@/components/List/RadioOptions';

type SortDirection = 'ascending' | 'descending';

export interface Sort<T> {
  key: keyof T;
  direction: SortDirection;
}

interface Props<T> {
  sort: Sort<T>;
  onChange: (sort: Sort<T>) => void;
  keys: (keyof T)[];
}

export const sortEntities = <T,>(entities: T[], sort: Sort<T>) => {
  return entities.sort((a, b) => {
    if (a[sort.key] < b[sort.key]) {
      return sort.direction === 'ascending' ? -1 : 1;
    }
    if (a[sort.key] > b[sort.key]) {
      return sort.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
};

const SortButton = <T,>({ sort, onChange, keys }: Props<T>) => {
  const [open, setOpen] = useState(false);

  const handleDirectionChange = useCallback(
    (direction: string) => {
      onChange({
        ...sort,
        direction: direction as SortDirection,
      });
    },
    [onChange, sort],
  );

  const handleKeyChange = useCallback(
    (key: string) => {
      onChange({
        ...sort,
        key: key as keyof T,
      });
    },
    [onChange, sort],
  );

  return (
    <>
      <button
        className="flex h-full items-center justify-center"
        onClick={() => setOpen(true)}
      >
        <MdSort className="h-8 w-8" />
      </button>
      <Transition appear show={open} as={Fragment}>
        <div>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 z-10 bg-black/25 transition-all"
              onClick={() => setOpen(false)}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-8 z-20 flex flex-col bg-white">
              <div className="flex justify-end p-2">
                <button type="button" onClick={() => setOpen(false)}>
                  <MdClose className="h-8 w-8" />
                </button>
              </div>
              <h2 className="header2 mb-2 border-b-4 border-roxtec px-4 pb-2">
                Sort order
              </h2>
              <RadioOptions
                name="sort-direction"
                options={['ascending', 'descending']}
                value={sort.direction}
                onChange={handleDirectionChange}
              />
              <h2 className="header2 my-2 border-b-4 border-roxtec px-4 pb-2">
                Sort by
              </h2>
              <RadioOptions
                name="sort-key"
                options={keys as string[]}
                value={sort.key as string}
                onChange={handleKeyChange}
              />
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </>
  );
};

export default SortButton;
