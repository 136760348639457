import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useCustomerAssetTransit } from '@/api/useTransits';
import Navbar from '@/components/Navbar';
import React from 'react';
import { classNames } from '@/classNames';
import { MdLoop } from 'react-icons/md';
import { useQueryClient } from '@tanstack/react-query';
import ErrorMessage from '@/components/ErrorMessage';
import { useTransitSubscription } from '@/api/useTransitGuards';
import { NavTarget } from '@/routes';
import LoadingScreen from '@/components/LoadingScreen';

const tabs = ['openings', 'information'];

const CustomerAssetTransit = () => {
  const queryClient = useQueryClient();
  const { customer_id, asset_id, transit_id } = useParams<{
    customer_id?: string;
    asset_id?: string;
    transit_id?: string;
  }>();

  const {
    data: transit,
    isLoading: isTransitLoading,
    isFetching,
    error,
  } = useCustomerAssetTransit(customer_id, asset_id, transit_id);

  useTransitSubscription(customer_id, asset_id, transit_id);

  return (
    <div className="flex h-full max-h-full flex-col">
      <Navbar
        back={NavTarget.CustomerAssetTransits(customer_id, asset_id)}
        title={transit?.name}
      />
      <div className="flex shrink border-b border-gray-200 bg-white">
        {tabs.map((tab) => (
          <NavLink
            key={tab}
            to={`./${tab}`}
            replace
            className={({ isActive }) =>
              classNames(
                'flex min-w-0 items-center justify-center overflow-hidden px-5 py-5 font-bold capitalize',
                isActive ? 'bg-roxtec text-white' : 'text-roxtec',
              )
            }
          >
            <span className="truncate">{tab}</span>
          </NavLink>
        ))}
        <div className="grow" />
        <button
          type="button"
          className="button m-2"
          onClick={() => queryClient.refetchQueries()}
        >
          <MdLoop
            className={classNames('h-6 w-6', isFetching && 'animate-spin')}
          />
        </button>
      </div>
      {isTransitLoading && <LoadingScreen />}
      {error && <ErrorMessage title="Error loading transit" error={error} />}
      {!error && !isTransitLoading && (
        <div className="overflow-auto bg-white">
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default CustomerAssetTransit;
