import React from 'react';
export const CameraMissing: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    width="178"
    height="178"
    viewBox="0 0 178 178"
    fill="none"
    className={className}
  >
    <g opacity="0.5">
      <path
        d="M88 110.189C76.2773 110.189 66.8145 100.585 66.8145 89.0031C66.8145 77.2804 76.4186 67.8175 88 67.8175C99.5815 67.8175 109.186 77.4216 109.186 89.0031C109.186 100.585 99.7227 110.189 88 110.189ZM88 73.3257C79.3846 73.3257 72.4639 80.3876 72.4639 88.8618C72.4639 97.4773 79.5258 104.398 88 104.398C96.6155 104.398 103.536 97.3361 103.536 88.8618C103.536 80.3876 96.6155 73.3257 88 73.3257Z"
        fill="currentColor"
      />
      <path
        d="M144.777 137.73H31.2227C24.7258 137.73 19.5 132.504 19.5 126.007V51.8577C19.5 45.3608 24.7258 40.1351 31.2227 40.1351H54.2443C54.8093 40.1351 55.233 39.8526 55.6567 39.5701L64.8371 29.401C66.2495 27.8474 68.2268 27 70.3454 27H105.655C107.773 27 109.751 27.8474 111.163 29.401L120.343 39.5701C120.626 39.9938 121.191 40.1351 121.756 40.1351H144.777C151.274 40.1351 156.5 45.3608 156.5 51.8577V126.148C156.5 132.504 151.274 137.73 144.777 137.73ZM31.2227 45.7845C27.9742 45.7845 25.1495 48.468 25.1495 51.8577V126.148C25.1495 129.397 27.833 132.222 31.2227 132.222H144.919C148.167 132.222 150.992 129.538 150.992 126.148V51.8577C150.992 48.6093 148.308 45.7845 144.919 45.7845H121.756C119.637 45.7845 117.66 44.9371 116.247 43.3835L107.067 33.2144C106.785 32.7907 106.22 32.6495 105.655 32.6495H70.3454C69.7804 32.6495 69.3567 32.932 68.933 33.2144L59.7526 43.3835C58.3402 44.9371 56.3629 45.7845 54.2443 45.7845H31.2227V45.7845ZM88 122.053C69.6392 122.053 54.8093 107.223 54.8093 88.8619C54.8093 70.501 69.6392 55.6711 88 55.6711C106.361 55.6711 121.191 70.501 121.191 88.8619C121.191 107.223 106.361 122.053 88 122.053ZM88 61.3206C72.7464 61.3206 60.4588 73.6082 60.4588 88.8619C60.4588 104.115 72.7464 116.403 88 116.403C103.254 116.403 115.541 104.115 115.541 88.8619C115.541 73.7495 103.254 61.3206 88 61.3206Z"
        fill="currentColor"
      />
    </g>
    <path opacity="0.3" d="M171 10L10 171" stroke="#333F46" strokeWidth="18" />
    <path d="M166 5L5 166" stroke="currentColor" strokeWidth="12" />
  </svg>
);
