import { Guard, GuardStatus } from '@/api/useGuard';
import React, {
  Fragment,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Transition } from '@headlessui/react';
import { MdClose } from 'react-icons/md';
import { ShieldIcon } from '@/components/Icon';
import RemoveGuardDialog from '@/components/Transit/RemoveGuardDialog';

interface Props {
  guard?: Guard;
  onClose: () => void;
}

const GuardInfoDialog: React.FC<Props> = ({ guard, onClose }) => {
  const open = useMemo(() => !!guard, [guard]);

  const status = useMemo<GuardStatus>(() => {
    if (guard?.status) {
      return guard?.status;
    }

    return '-';
  }, [guard?.status]);

  const [removeGuardId, setRemoveGuardId] = useState<string | undefined>();

  const handleClose = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      if (!open) return;

      e.stopPropagation();
      onClose();
    },
    [onClose, open],
  );

  const handleRemove = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      if (!open || !guard?.id) return;

      e.stopPropagation();
      setRemoveGuardId(guard.id);
    },
    [guard?.id, open],
  );

  const handleGuardRemoved = useCallback(() => {
    setRemoveGuardId(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <RemoveGuardDialog
        id={removeGuardId}
        onRemoved={handleGuardRemoved}
        onClose={() => setRemoveGuardId(undefined)}
      />
      <Transition appear show={open} as={Fragment}>
        <div>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 z-10 bg-black/25 transition-all"
              onClick={handleClose}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="transform translate-y-full"
            enterTo="transform translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="transform translate-y-0"
            leaveTo="transform translate-y-full"
          >
            <div className="fixed inset-x-0 bottom-0 z-20 flex flex-col bg-white shadow-lg">
              <div className="flex justify-end p-2">
                <button type="button" onClick={handleClose}>
                  <MdClose className="h-8 w-8" />
                </button>
              </div>
              <div className="flex flex-col items-center justify-center gap-4 px-5">
                <ShieldIcon className="!h-44 !w-44" status={status} />
                <ul className="m-4 flex w-full flex-col divide-y-2 divide-gray-200 border-2 border-gray-200">
                  <li className="flex items-center justify-between p-4 ">
                    <span className="text-gray-500">Guard ID</span>
                    <span className="font-bold text-gray-800">{guard?.id}</span>
                  </li>
                  <li className="flex items-center justify-between p-4 ">
                    <span className="text-gray-500">Opening</span>
                    <span className="font-bold text-gray-800">
                      {guard?.placement?.opening_id ?? '-'}
                    </span>
                  </li>
                  <li className="flex items-center justify-between p-4 ">
                    <span className="text-gray-500">Last heard from</span>
                    <span className="font-bold text-gray-800">
                      {guard?.timestamp.toFormat('yyyy-MM-dd hh:mm:ss') ?? '-'}
                    </span>
                  </li>
                  <li className="flex items-center justify-between p-4 ">
                    <span className="text-gray-500">Status</span>
                    <span className="font-bold text-gray-800">
                      {guard?.status ?? '-'}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-4 p-4">
                <button
                  type="button"
                  onClick={handleRemove}
                  className="navbar-button bg-error"
                >
                  Remove Guard
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </>
  );
};

export default GuardInfoDialog;
