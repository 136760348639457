import React from 'react';
import { GuardStatus } from '@/api/useGuard';
import { cva, type VariantProps } from 'class-variance-authority';

export const shield = cva('h-4 w-4', {
  variants: {
    status: {
      NI: 'text-status-NI fill-status-NI',
      XI: 'text-status-XI fill-status-XI animate-blink',
      XP: 'text-status-XP fill-status-XP animate-blink',
      M1: 'text-status-M1 fill-status-M1',
      M2: 'text-status-M2 fill-status-M2',
      GA: 'text-status-GA fill-status-GA animate-blink',
      P1: 'text-status-P1 fill-status-P1',
      P2: 'text-status-P2 fill-status-P2',
      P3: 'text-status-P3 fill-status-P3',
      C: 'text-status-C fill-status-C',
      '-': 'text-status-300 fill-gray-300',
    } as Record<GuardStatus, string>,
  },
  defaultVariants: {
    status: '-',
  },
});

interface Props extends VariantProps<typeof shield> {
  className?: string;
}

export const ShieldIcon: React.FC<Props> = ({ className, status }) => (
  <svg width="54px" height="66px" viewBox="0 0 54 66" className={className}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        className={shield({ status })}
        d="M26.481408,65.842108 C26.640784,65.9225995 26.881408,66 27.040784,66 C27.20016,66 27.440784,65.9195085 27.60016,65.842108 L35.4408,61.7988294 C46.8816,55.8546963 54,44.2818655 54,31.4433306 L54,10.4438973 C54,9.96713027 53.681248,9.49347004 53.2,9.33559387 L27.4408,0.0603686155 C27.200176,-0.0201228718 26.881424,-0.0201228718 26.6408,0.0603686155 L0.8,9.33242366 C0.318752,9.49031569 -1.59872116e-13,9.96708271 -1.59872116e-13,10.4407271 L-1.59872116e-13,31.3624904 C-1.59872116e-13,44.1241478 7.11872,55.7738561 18.5592,61.7179892 L26.481408,65.842108 Z"
      />
      <path
        d="M27,66 C27.1048557,66 29.9996772,64.5996098 35.6844645,61.7988294 C46.9750578,55.8546963 54,44.2818655 54,31.4433306 L54,10.4438973 C54,9.96713027 53.6854329,9.49347004 53.2105032,9.33559387 L27.7894968,0.0603686155 C27.5520319,-0.0201228718 27.2374648,-0.0201228718 27,0.0603686155 C26.8951443,44.0201229 26.8951443,66 27,66 Z"
        fillOpacity="0.3"
        fill="#FFFFFF"
      />
    </g>
  </svg>
);
