import React, { Children } from 'react';
import EmptyTransit from '@/components/Icon/EmptyTransit';
import LoadingScreen from '@/components/LoadingScreen';

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
  isFetched: boolean;
  emptyMessage?: React.ReactNode;
}

const List: React.FC<Props> = ({
  children,
  emptyMessage = 'No items found',
  isLoading,
  isFetched,
}) => {
  const childArray = Children.toArray(children);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isFetched && childArray.length === 0) {
    return (
      <div className="flex grow flex-col items-center justify-center gap-4 p-4">
        <EmptyTransit className="h-48 w-48" />
        <p className="text-gray-500">{emptyMessage}</p>
      </div>
    );
  }

  return <ul className="flex flex-col overflow-auto bg-white">{children}</ul>;
};

export default List;
