import React from 'react';

export const AssetIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Asset" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="group" fill="#333F46" fillRule="nonzero">
        <path
          d="M70,0 L70,39 L0,29 L0,100 L100,100 L100,0 L70,0 Z M70,90 L10,90 L10,41 L70,49.5 L70,90 Z M90,90 L80,90 L80,10 L90,10 L90,90 Z"
          id="Shape"
        />
        <polygon id="Path" points="25 60 35 60 35 70 25 70" />
        <polygon id="Path" points="45 60 55 60 55 70 45 70" />
      </g>
    </g>
  </svg>
);
