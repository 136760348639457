import React, { useState } from 'react';
import SidePanel from './SidePanel';
import { MdKeyboardArrowLeft, MdMenu } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface Props {
  title?: string;
  icon?: React.ReactNode;
  back?: string;
}

const Navbar: React.FC<Props> = ({ title, icon, back }) => {
  const navigate = useNavigate();
  const [show_menu, setShowMenu] = useState(false);

  return (
    <div className="flex h-[60px] max-h-[60px] min-h-[60px] w-full justify-between border-b border-gray-200 bg-white">
      <div className="flex w-[60px] items-center justify-center">
        {back && (
          <button
            onClick={() => {
              navigate(back);
            }}
          >
            <MdKeyboardArrowLeft className="h-[30px] w-[30px] text-gray-500" />
          </button>
        )}
      </div>
      <div className="flex max-h-full grow items-center justify-center truncate text-lg font-bold">
        <p className="inline-block truncate">{title}</p>
        {icon}
      </div>
      <div className="flex w-[60px] items-center justify-center">
        <button
          type="button"
          onClick={() => {
            setShowMenu(!show_menu);
          }}
        >
          <MdMenu className="h-[30px] w-[30px] text-gray-500" />
        </button>
        <SidePanel
          open={show_menu}
          onClose={() => {
            setShowMenu(!show_menu);
          }}
        />
      </div>
    </div>
  );
};

export default Navbar;
