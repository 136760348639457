import React from 'react';

export const CameraSwitch: React.FC = () => (
  <svg width="44" height="42" viewBox="0 0 44 42" fill="none">
    <path
      d="M22.6404 26.1506C20.1135 26.1506 18.0737 24.0804 18.0737 21.5839C18.0737 19.057 20.144 17.0172 22.6404 17.0172C25.1369 17.0172 27.2071 19.0874 27.2071 21.5839C27.2071 24.0804 25.1673 26.1506 22.6404 26.1506ZM22.6404 18.2046C20.7833 18.2046 19.2915 19.7268 19.2915 21.5535C19.2915 23.4106 20.8137 24.9024 22.6404 24.9024C24.4975 24.9024 25.9893 23.3801 25.9893 21.5535C25.9893 19.7268 24.4975 18.2046 22.6404 18.2046Z"
      fill="white"
    />
    <path
      d="M34.8793 32.0873H10.4019C9.00145 32.0873 7.875 30.9609 7.875 29.5604V13.577C7.875 12.1765 9.00145 11.0501 10.4019 11.0501H15.3644C15.4861 11.0501 15.5775 10.9892 15.6688 10.9283L17.6477 8.73631C17.9522 8.40142 18.3784 8.21875 18.8351 8.21875H26.4462C26.9029 8.21875 27.3291 8.40142 27.6335 8.73631L29.6124 10.9283C29.6733 11.0197 29.7951 11.0501 29.9169 11.0501H34.8793C36.2798 11.0501 37.4062 12.1765 37.4062 13.577V29.5909C37.4062 30.9609 36.2798 32.0873 34.8793 32.0873ZM10.4019 12.2679C9.70168 12.2679 9.09278 12.8463 9.09278 13.577V29.5909C9.09278 30.2911 9.67123 30.9 10.4019 30.9H34.9098C35.61 30.9 36.2189 30.3215 36.2189 29.5909V13.577C36.2189 12.8768 35.6405 12.2679 34.9098 12.2679H29.9169C29.4602 12.2679 29.034 12.0852 28.7295 11.7503L26.7506 9.55831C26.6898 9.46698 26.568 9.43653 26.4462 9.43653H18.8351C18.7133 9.43653 18.6219 9.49742 18.5306 9.55831L16.5517 11.7503C16.2473 12.0852 15.821 12.2679 15.3644 12.2679H10.4019V12.2679ZM22.6406 28.708C18.6828 28.708 15.4861 25.5113 15.4861 21.5535C15.4861 17.5957 18.6828 14.399 22.6406 14.399C26.5984 14.399 29.7951 17.5957 29.7951 21.5535C29.7951 25.5113 26.5984 28.708 22.6406 28.708ZM22.6406 15.6168C19.3526 15.6168 16.7039 18.2655 16.7039 21.5535C16.7039 24.8415 19.3526 27.4902 22.6406 27.4902C25.9286 27.4902 28.5773 24.8415 28.5773 21.5535C28.5773 18.2959 25.9286 15.6168 22.6406 15.6168Z"
      fill="white"
    />
    <path
      d="M1.00002 15.4375V8.21875C1.00002 8.21875 0.974803 4.63462 2.64065 2.96875C4.30649 1.30288 8.87502 1 8.87502 1H16.0938L11.5 5.59375"
      stroke="white"
      strokeWidth="1.96875"
    />
    <path
      d="M43 25.9375L43 33.1562C43 33.1562 43.0252 36.7404 41.3594 38.4062C39.6935 40.0721 35.125 40.375 35.125 40.375L27.9062 40.375L32.5 35.7812"
      stroke="white"
      strokeWidth="1.96875"
    />
  </svg>
);
