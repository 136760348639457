import React from 'react';

interface Props {
  title?: React.ReactNode;
  subtitle?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const ListItem: React.FC<Props> = ({ title, subtitle, startIcon, endIcon }) => {
  return (
    <li className="border-b border-gray-200">
      <div className="flex items-center gap-4 px-6 py-3">
        {startIcon}
        <div className="flex grow flex-col">
          <span className="font-bold">{title}</span>
          {subtitle && (
            <span className="text-sm text-gray-400">{subtitle}</span>
          )}
        </div>
        {endIcon}
      </div>
    </li>
  );
};

export default ListItem;
