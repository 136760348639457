import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import { NavTarget } from '@/routes';
import React from 'react';

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (!isAuthenticated && !isLoading) {
    return <Navigate to={NavTarget.Home} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
