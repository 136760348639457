const EmptyTransit: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="66"
    height="118"
    viewBox="0 0 66 118"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fill="none">
      <g transform="translate(1 1)">
        <rect
          stroke="#DDE3E8"
          fill="#FFF"
          x="-.5"
          y="-.5"
          width="65"
          height="117"
          rx="1"
        />
        <rect fill="#DDE3E8" x="6" y="21" width="52" height="89" rx="1" />
        <rect
          fill="#F2F8FC"
          opacity=".5"
          x="12"
          y="31"
          width="40"
          height="69"
          rx="1"
        />
        <path fill="#FFF" opacity=".6" d="M6 110l6-10h40l6 10z" />
        <path fill="#FFF" opacity=".5" d="M58 21l-6 10H12L6 21z" />
      </g>
      <rect
        fill="#DDE3E8"
        opacity=".2"
        x="7"
        y="5"
        width="52"
        height="14"
        rx="1"
      />
    </g>
  </svg>
);

export default EmptyTransit;
