import React from 'react';

export const MagnifyingGlass: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg width="96px" height="99px" viewBox="0 0 96 99" className={className}>
    <g id="search" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="np_search_1900031_000000" fill="currentColor" fillRule="nonzero">
        <path
          d="M36.738,6.207 C28.3278,6.207 20.824,9.8125 15.418,15.2148 C10.0118,20.621 6.711,28.1288 6.711,36.2348 C6.711,44.645 10.0157,52.1488 15.418,57.5548 C20.8242,62.961 28.332,66.2618 36.738,66.2618 C44.8474,66.2618 52.355,62.9571 57.758,57.5548 C63.1642,52.1486 66.7658,44.6408 66.7658,36.2348 C66.7658,28.1254 63.1603,20.6178 57.758,15.2148 C52.3518,9.8086 44.844,6.207 36.738,6.207 Z M11.211,10.7109 C17.5157,4.1054 26.828,0.1989 36.738,0.1989 C46.6482,0.1989 55.656,4.1012 62.265,10.7109 C68.8705,17.3164 72.777,26.3279 72.777,36.2379 C72.777,46.4489 68.8747,55.4569 62.265,61.7649 C55.6595,68.3704 46.648,72.5769 36.738,72.5769 C26.8278,72.5769 17.519,68.3738 11.211,61.7649 C4.6055,55.4602 0.399,46.4489 0.399,36.2379 C0.399,26.3277 4.6021,17.3199 11.211,10.7109 Z"
          id="Shape"
        />
        <path
          d="M56.559,63.266 C55.3559,62.0629 55.3559,59.9613 56.559,58.7621 C57.7621,57.559 59.5629,57.559 60.7621,58.7621 L94.6961,92.6961 C95.8992,93.8992 95.8992,96.0008 94.6961,97.2 C93.493,98.4031 91.3914,98.4031 90.1922,97.2 L56.559,63.266 Z"
          id="Path"
        />
        <path
          d="M62.5819036,13.1167 C62.5819036,14.6167 61.3788036,16.1206 59.5780036,16.1206 C53.8710036,16.1206 48.7660036,18.5229 45.1640036,22.1284 C41.5585036,25.7339 39.4570036,30.8354 39.4570036,36.2414 C39.4570036,38.0422 37.9570036,39.5461 36.1523036,39.5461 C34.3515036,39.5461 33.1484036,38.0461 33.1484036,36.2414 C33.1484036,29.0344 36.1523036,22.4294 40.9570036,17.6244 C45.7617036,12.8197 52.3670036,9.8158 59.5740036,9.8158 C61.3788036,9.812 62.5819036,11.312 62.5819036,13.1167 Z"
          id="Path"
          transform="translate(46, 25) rotate(-270.000000) translate(-46, -25) "
        />
      </g>
    </g>
  </svg>
);
