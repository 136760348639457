import React from 'react';

export const RoxtecLogo: React.FC = () => (
  <svg x="0px" y="0px" viewBox="0 0 163.6 40.7" width="163.6px" height="40.7px">
    <path
      d="M69.8,32.3c-0.3-0.8-0.4-1.8-0.4-2.8l-0.1-2.6c-0.2-3.5-0.1-5.5-3.8-6.6c2.4-0.2,3.5-2.2,3.5-4.5V14
  	          c0-3.3-1.7-6.1-5.8-6.1H51v24.4h7v-8.8c3.9,0,4.2,1.1,4.3,3.2l0.2,2.9c0,0.9,0.1,1.8,0.4,2.7H69.8z M62,15.9c0,1-0.2,2-1.7,2H58
  	          v-4.4h1.8C61.7,13.5,62,14.4,62,15.9z M84.4,32.3c3.4,0,5.9-2,5.9-5.4v-8.8c0-3.4-2.5-5.4-5.9-5.4h-6c-3.4,0-5.9,2-5.9,5.4v8.8
  	          c0,3.4,2.5,5.4,5.9,5.4H84.4z M81.4,27.7c-1.8,0-2-1.1-2-2.1v-6.1c0-1,0.2-2.1,2-2.1s2,1.1,2,2.1v6.1C83.4,26.6,83.2,27.7,81.4,27.7
  	          z M103.9,32.3h7.6l-6.6-10.4l5.8-9.2h-7.4l-2,3.5l-2.2-3.5h-7.6l6,9.4l-6.6,10.2h7.4l2.7-4.6L103.9,32.3z M122.8,17.4v-4.6h-3.2V8.1
  	          h-0.7l-6.2,4.2v14.6c0,3.4,2.5,5.4,5.9,5.4h4.4v-4.6h-1.4c-1.8,0-2-1.1-2-2.1v-8.2H122.8z M136,20.5h-3.5v-1c0-1,0.2-2.1,1.8-2.1
  	          s1.8,1.1,1.8,2.1v1H136z M163.6,19.5v-1.3c0-3.4-2.5-5.4-5.9-5.4H152c-3.4,0-5.9,2-5.9,5.4V27c0,3.4,2.5,5.4,5.9,5.4h5.7
  	          c3.4,0,5.9-2,5.9-5.4v-1.3h-6.9c0,1-0.2,2.1-1.6,2.1c-1.8,0-2-1.1-2-2.1v-6.1c0-1,0.2-2.1,2-2.1c1.4,0,1.6,1.1,1.6,2.1h6.9V19.5z
  	          M131.4,32.3h5.7c3.4,0,5.9-2,5.9-5.4v-1.3h-6.9c0,1-0.2,2.1-1.8,2.1s-1.8-1.1-1.8-2.1v-2.1H143v-5.3c0-3.4-2.5-5.4-5.9-5.4h-5.7
  	          c-3.4,0-5.9,2-5.9,5.4V27C125.5,30.3,128,32.3,131.4,32.3z"
    />

    <rect width="40.8" height="40.7" fill="#0091d3" />
    <path
      fill="#000"
      d="M17,0h-5.1c6.9,4.1,11.6,11.7,11.6,20.3c0,8.7-4.6,16.2-11.5,20.3H17c5.8-4.9,9.4-12.2,9.4-20.3
  	          C26.4,12.2,22.8,4.9,17,0"
    />
    <path
      fill="#000"
      d="M20.2,20.4C20.2,9.1,11.2,0,0,0v3c9.6,0,17.3,7.7,17.3,17.3C17.3,30,9.6,37.7,0,37.7v3
  	          C11.2,40.7,20.2,31.6,20.2,20.4"
    />
    <path
      fill="transparent"
      d="M29.7,20.4c0,7.9-3,15-7.9,20.3l18.7,0V0H21.8C26.7,5.4,29.7,12.5,29.7,20.4"
    />
    <path fill="#000" d="M0,6.4v28.4c7.8,0,14.2-6.4,14.2-14.2S7.9,6.4,0,6.4" />
  </svg>
);
