import { create } from 'zustand';

interface ErrorStoreError {
  header: string;
  message: string;
}

interface ErrorStore {
  error: ErrorStoreError | null;
  setError: (error: ErrorStoreError | null) => void;
}

export const useError = create<ErrorStore>()((set, get) => ({
  error: null,
  setError: (error) => set({ error }),
}));
