import { Transition } from '@headlessui/react';
import React, {
  Fragment,
  MouseEventHandler,
  useCallback,
  useMemo,
} from 'react';
import { MdClose } from 'react-icons/md';
import { useGuardMutations } from '@/api/useGuard';
import Spinner from '@/components/Spinner';

interface Props {
  id?: string;
  onRemoved: () => void;
  onClose: () => void;
}
const RemoveGuardDialog: React.FC<Props> = ({ id, onRemoved, onClose }) => {
  const open = useMemo(() => !!id, [id]);
  const { removeGuard } = useGuardMutations();

  const handleClose = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      if (!open || removeGuard.isLoading) return;

      e.stopPropagation();
      onClose();
    },
    [removeGuard.isLoading, onClose, open],
  );

  const handleRemove = useCallback(() => {
    if (!id) return;

    void removeGuard.mutateAsync(id).then(() => {
      onRemoved();
    });
  }, [id, onRemoved, removeGuard]);

  return (
    <Transition appear show={open} as={Fragment}>
      <div>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 z-30 bg-black/25 transition-all"
            onClick={handleClose}
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="transform translate-y-full"
          enterTo="transform translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="transform translate-y-0"
          leaveTo="transform translate-y-full"
        >
          <div className="fixed inset-x-0 bottom-0 z-40 flex flex-col bg-white shadow-lg">
            <div className="flex justify-end p-2">
              <button type="button" onClick={handleClose}>
                <MdClose className="h-8 w-8" />
              </button>
            </div>
            <div className="flex flex-col items-center justify-center gap-4 px-5 py-4">
              <p className="text-center">
                Are you sure you want to remove the guard?
              </p>
              <h1 className="text-2xl font-bold">{id}</h1>
            </div>
            <div className="flex flex-col gap-4 p-4">
              <button
                type="button"
                onClick={handleRemove}
                className="navbar-button relative bg-error"
                disabled={removeGuard.isLoading}
              >
                Remove Guard
                {removeGuard.isLoading && (
                  <div className="absolute right-4">
                    <Spinner className="text-white" />
                  </div>
                )}
              </button>
              <button
                type="button"
                onClick={handleClose}
                className="navbar-button"
                disabled={removeGuard.isLoading}
              >
                Cancel
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

export default RemoveGuardDialog;
