import { useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorDialog from './components/ErrorDialog';
import Callback from './Callback';
import ConfirmUpdateAlert from './ConfirmUpdateAlert';
import ErrorBoundary from './components/ErrorBoundary';
import { useVersionUpdated } from '@/useVersion';
import { useConfig, useConfigQuery } from '@/useConfig';
import { useError } from '@/useError';
import Root from '@/views/Root';
import { Auth0Provider } from '@auth0/auth0-react';
import Logout from '@/views/Logout';
import BrowseCustomers from '@/views/BrowseCustomers';
import ProtectedRoute from '@/components/ProtectedRoute';
import TransitFinder from '@/views/TransitFinder';
import { NavTarget } from '@/routes';
import CustomerAssets from '@/views/CustomerAssets';
import CustomerAssetTransits from '@/views/CustomerAssetTransits';
import CustomerAssetTransit from '@/views/CustomerAssetTransit';
import { Navigate } from 'react-router';
import TransitOpenings from '@/components/Transit/TransitOpenings';
import TransitInformation from '@/components/Transit/TransitInformation';
import PageNotFound from './views/PageNotFound';

const App = () => {
  const [show_update_alert, setShowUpdateAlert] = useState(false);

  useVersionUpdated(() => {
    setShowUpdateAlert(true);
  });

  const { isLoading: isConfigLoading } = useConfigQuery();
  const { loaded: configLoaded, auth0 } = useConfig(
    useCallback(
      (state) => ({
        loaded: state.loaded,
        auth0: state.auth0,
      }),
      [],
    ),
  );

  const { error, setError } = useError();

  if (isConfigLoading) {
    return null;
  }

  if (!configLoaded) {
    return <p>Failed to load configuration</p>;
  }

  return (
    <ErrorBoundary>
      {error && (
        <ErrorDialog
          header={error.header}
          message={error.message}
          onClose={() => setError(null)}
        />
      )}
      <ConfirmUpdateAlert
        open={show_update_alert}
        onClose={() => setShowUpdateAlert(false)}
      />
      <Auth0Provider
        domain={auth0.domain}
        clientId={auth0.clientID}
        authorizationParams={{
          redirect_uri: window.location.origin + '/callback',
          audience: auth0.audience,
        }}
        cacheLocation="localstorage"
      >
        <Routes>
          <Route index element={<Root />} />
          <Route path={NavTarget.Callback} element={<Callback />} />
          <Route path={NavTarget.Logout} element={<Logout />} />
          <Route element={<ProtectedRoute />}>
            <Route path={NavTarget.TransitFinder} element={<TransitFinder />} />
            <Route path={NavTarget.Customers} element={<BrowseCustomers />} />
            <Route
              path={NavTarget.CustomerAssets()}
              element={<CustomerAssets />}
            />
            <Route
              path={NavTarget.CustomerAssetTransits()}
              element={<CustomerAssetTransits />}
            />
            <Route
              path={NavTarget.CustomerAssetTransit()}
              element={<CustomerAssetTransit />}
            >
              <Route index element={<Navigate to="./openings" replace />} />
              <Route path="openings" element={<TransitOpenings />} />
              <Route path="information" element={<TransitInformation />} />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Auth0Provider>
    </ErrorBoundary>
  );
};

export default App;
