import { useAuth0 } from '@auth0/auth0-react';
import { useConfig } from '@/useConfig';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { DateTime } from 'luxon';

export type GuardStatus =
  | 'NI'
  | 'XI'
  | 'XP'
  | 'M1'
  | 'M2'
  | 'GA'
  | 'P1'
  | 'P2'
  | 'P3'
  | 'C'
  | '-';

export interface GuardPlacement {
  customer_id: string;
  asset_id: string;
  transit_id: string;
  opening_id: string;
}

export interface Guard {
  request_id?: number;
  id: string;
  status: GuardStatus;
  timestamp: DateTime;
  gateway_id: string;
  rssi: number;
  snr: number;
  dr: string;
  placement?: GuardPlacement;
}

export interface ApiGuard extends Omit<Guard, 'timestamp'> {
  timestamp: string;
}

export const reviveApiGuard = (apiGuard: ApiGuard): Guard => ({
  ...apiGuard,
  timestamp: DateTime.fromISO(apiGuard.timestamp),
});

export const useGuard = (id?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Guard, AxiosError>({
    queryKey: ['guards', id],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<ApiGuard>(
        `${api_base_url}/guards/${id ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return reviveApiGuard(response.data);
    },
    enabled: !!id,
  });
};

interface LinkGuardParams {
  id: string;
  placement: GuardPlacement;
  status: GuardStatus;
}

export const useGuardMutations = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();
  const queryClient = useQueryClient();

  const removeGuard = useMutation({
    mutationFn: async (id: string) => {
      const accessToken = await getAccessTokenSilently();
      await axios.delete(`${api_base_url}/guards/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
  });

  const linkGuard = useMutation<void, AxiosError, LinkGuardParams>({
    mutationFn: async ({ id, status, placement }) => {
      const accessToken = await getAccessTokenSilently();
      await axios.post(
        `${api_base_url}/guards/${id}`,
        {
          ...placement,
          status,
          wakeup_interval_minutes: 720,
          alarm_sleep_interval_minutes: 5,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    onSuccess: (_data, { placement: { customer_id, asset_id, transit_id } }) =>
      queryClient.invalidateQueries({
        queryKey: [
          'customers',
          customer_id,
          'assets',
          asset_id,
          'transits',
          transit_id,
        ],
      }),
  });

  return { removeGuard, linkGuard };
};
