const IllustrationForWizardPage1: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <svg
      width="600"
      height="400"
      viewBox="0 0 600 400"
      fill="none"
      className={className}
    >
      <path
        d="M337.618 205.5L328.199 206.217L326.513 206.346L320.513 206.803L310.658 207.553L300.687 208.313L298.606 208.471L288.635 209.231L280.954 209.816L285.881 218.502L289.279 224.492L292.079 229.428L293.686 232.261L294.276 233.301L299.21 241.999L304.144 250.697L305.591 253.249L307.435 256.499L312.535 247.897L312.864 247.342L315.166 243.458L316.698 244.228L324.892 248.346L333.827 252.837L342.762 257.327L351.697 261.818L360.633 266.308L369.568 270.799L378.503 275.29L387.438 279.78L396.373 284.271L405.308 288.761L414.243 293.252L417.464 294.871L422.049 285.984L426.633 277.096L431.218 268.209L431.424 267.809L422.454 263.39L413.483 258.972L404.512 254.553L395.542 250.134L386.571 245.715L377.6 241.296L368.629 236.877L359.659 232.458L350.095 227.747L341.938 223.729L332.967 219.31L330.25 217.971L335.336 209.362L337.618 205.5Z"
        fill="black"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M289.279 224.492L281.441 230.703L273.604 236.913L265.766 243.123L257.928 249.333L250.09 255.544L242.252 261.754L234.414 267.964L226.576 274.174L218.738 280.385L210.9 286.595L207.431 289.344L204.566 291.614L196.728 297.824L188.89 304.035L181.052 310.245L173.214 316.455L165.377 322.665L157.539 328.876L149.701 335.086L145.013 338.8L137.417 344.819L136.914 345.218L132.497 348.717L130.919 350.041L130.898 350.061L129.442 351.43L128.102 352.85L126.935 354.264L125.97 355.636L125.232 356.93L124.737 358.114L124.498 359.159L124.229 363.629L124.16 364.781L124.171 365.652L124.444 366.332L130.163 374.536L135.881 382.739L141.599 390.943L141.978 391.488"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M350.095 227.747L357.837 221.418L365.58 215.09L373.323 208.761L381.065 202.432L388.808 196.104L396.551 189.775L404.293 183.447L412.036 177.118L419.779 170.79L427.521 164.461L435.264 158.132L443.007 151.804L450.749 145.475L458.492 139.147L466.235 132.818L473.977 126.489L481.72 120.161L489.463 113.832L491.113 112.484L493.044 108.876L496.456 99.4769L499.868 90.0769L503.28 80.6769L506.692 71.2769L508.617 65.9759L509.877 62.1099L510.182 60.8389L510.043 59.9869L507.688 58.0479L507.089 57.6619L501.243 57.7999L500.239 57.9159L498.915 58.5359L497.364 59.6179L492.538 63.4419L487.857 67.1509L480.019 73.3609L472.181 79.5719L466.171 84.3339L465.272 85.0459L457.434 91.2559L452.743 94.9739L447.191 99.3719L439.353 105.583L431.515 111.793L423.677 118.003L415.839 124.214L408.002 130.424L400.164 136.634L392.326 142.844L391.392 143.585L383.554 149.795L381.346 151.544L380.525 152.194L372.688 158.405L364.85 164.615L357.012 170.825L349.174 177.035L341.336 183.246L333.498 189.456L325.66 195.666L317.822 201.877L310.658 207.553"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M507.291 63.7468L505.996 64.3968L504.455 65.4968L496.64 71.7358L496.08 72.1828L488.266 78.4218L480.451 84.6608L473.255 90.4058L465.44 96.6458L457.625 102.885L454.269 105.565L446.454 111.804L438.639 118.043L430.824 124.282L423.009 130.522L415.194 136.761L407.38 143L399.565 149.239L391.75 155.479L383.935 161.718L376.12 167.957L368.305 174.197L360.491 180.436L352.676 186.675L344.861 192.914L337.046 199.154L328.199 206.217"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M294.276 233.301L286.461 239.54L278.646 245.779L270.831 252.018L263.017 258.258L255.202 264.497L247.387 270.736L239.572 276.976L231.668 283.286L223.853 289.525L217.262 294.788L209.447 301.027L201.632 307.266L193.817 313.506L186.002 319.745L178.188 325.984L170.373 332.223L162.558 338.463L154.743 344.702L151.795 347.056L143.98 353.295L139.258 357.065L138.6 357.618L137.674 358.395L136.183 359.782L134.822 361.189L133.626 362.581L132.627 363.922"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M444.749 23.6179L447.178 15.1409L447.379 13.8659L447.779 9.42393L447.53 8.50693L445.333 6.69793L444.642 6.23193L443.576 6.26993L438.883 7.18793L437.521 7.71693L435.959 8.71093L427.927 14.6689L419.896 20.6269L411.864 26.5839L403.833 32.5419L395.801 38.4999L387.77 44.4579L379.738 50.4149L371.707 56.3729L363.675 62.3309L355.644 68.2889L347.612 74.2469L339.581 80.2039L331.549 86.1619L323.518 92.1199L315.486 98.0779L307.455 104.035L299.423 109.993L291.392 115.951L283.36 121.909L275.329 127.866L267.297 133.824L259.266 139.782L251.234 145.74L243.203 151.697L235.171 157.655L227.14 163.613L219.108 169.571L211.077 175.528L203.045 181.486L195.014 187.444L186.982 193.402L178.95 199.359L170.919 205.317L162.887 211.275L154.856 217.233L146.824 223.19L138.793 229.148L130.761 235.106L122.73 241.064L114.698 247.021L106.667 252.979L98.635 258.937L90.604 264.895L82.572 270.852L74.358 276.946L72.811 278.176L71.373 279.486L70.083 280.843L68.972 282.213L68.069 283.56L67.397 284.849L66.972 286.048L65.59 290.107L65.41 291.187L65.494 292.116L65.84 292.871L71.648 301.011L77.457 309.151L83.266 317.291L87.043 322.584L93.273 330.407L99.502 338.229L105.732 346.052L111.961 353.874L118.191 361.697L124.42 369.52L130.65 377.342L136.88 385.165L143.109 392.987L143.854 393.922L147.644 393.223L155.387 386.894L163.129 380.566L170.872 374.237L178.615 367.908L186.357 361.58L194.1 355.251L201.843 348.923L209.585 342.594L217.328 336.265L225.071 329.937L232.813 323.608L240.556 317.28L248.299 310.951L256.041 304.623L263.784 298.294L271.527 291.965L279.269 285.637L287.012 279.308L294.755 272.98L302.497 266.651L310.24 260.322L317.983 253.994L324.892 248.346"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M91.419 322.557L85.722 314.338L80.025 306.119L74.328 297.901L73.173 296.233L72.87 295.523L72.822 294.642L73.032 293.609L73.497 292.451L74.205 291.197L75.139 289.879L76.275 288.529L77.586 287.183L79.016 285.894L79.037 285.876L80.591 284.639L88.602 278.654L96.613 272.669L104.624 266.684L112.635 260.699L120.647 254.714L128.658 248.729L136.669 242.744L144.68 236.759L152.691 230.774L160.702 224.788L168.714 218.803L176.725 212.818L184.736 206.833L192.747 200.848L200.758 194.863L208.769 188.878L216.78 182.893L224.792 176.908L232.803 170.923L240.814 164.937L248.825 158.952L256.836 152.967L264.847 146.982L272.859 140.997L280.87 135.012L288.881 129.027L296.892 123.042L304.903 117.057L312.914 111.072L320.925 105.087L328.937 99.101L336.948 93.116L344.959 87.131L352.97 81.146L360.981 75.161L368.992 69.176L377.004 63.191L385.015 57.206L393.026 51.221L401.037 45.236L409.048 39.251L417.059 33.265L425.071 27.28L433.082 21.295L437.764 17.797L442.579 14.2L444.133 13.19L445.469 12.633L446.497 12.564L447.148 12.986"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M127.17 365.357L126.641 366.527L126.371 367.551L126.365 368.402L126.622 369.062L132.319 377.281L138.016 385.499L141.985 391.226L143.854 393.922"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M65.59 290.107L66.03 288.903"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M510.043 59.9869L509.464 59.6179L508.483 59.7589"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M298.606 208.471L306.46 202.281L314.313 196.09L322.167 189.9L330.021 183.709L337.874 177.519L345.728 171.328L353.581 165.138L361.435 158.947L369.288 152.757L376.76 146.867L384.614 140.676L386.375 139.288L394.228 133.098L396.129 131.599L388.768 124.83L381.407 118.062L374.046 111.293L370.783 108.292L368.355 106.059L360.994 99.2899L353.633 92.5219L351.859 90.8909L343.865 96.8989L335.87 102.906L327.876 108.913L319.881 114.921L311.887 120.928L303.892 126.935L295.898 132.943L287.903 138.95L279.909 144.957L271.914 150.965L263.92 156.972L255.926 162.979L247.931 168.987L239.937 174.994L231.942 181.001L223.948 187.009L215.953 193.016L207.959 199.023L203.03 202.727L209.817 210.071L216.604 217.416L219.163 220.185L221.54 222.757L228.327 230.102L235.114 237.446L241.901 244.79L246.381 249.638L254.234 243.448L262.088 237.257L269.941 231.067L277.795 224.876L285.881 218.502"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M79.0161 285.894L85.3041 293.67L91.5911 301.446L97.8781 309.223L99.7471 311.534L102.06 314.395L108.348 322.171L114.635 329.947L120.923 337.723L127.21 345.499L130.898 350.061"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
      <path
        d="M492.538 63.4419L484.856 57.0399L477.174 50.6379L469.491 44.2369L461.809 37.8349L454.127 31.4329L446.445 25.0309L444.749 23.6179L444.235 23.1889L442.278 21.5579"
        stroke="black"
        strokeWidth="1"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { IllustrationForWizardPage1 };
