import { useAuth0 } from '@auth0/auth0-react';
import { useConfig } from '@/useConfig';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { GuardStatus } from '@/api/useGuard';

export interface Transit {
  id: string;
  customer_id: string;
  asset_id: string;
  name: string;
  location: string;
  geometry: string;
  rows: number;
  columns: number;
  openings: TransitOpenings;
  tag_serial?: string;
}

export interface TransitOpening {
  guard_id: string;
  status: GuardStatus;
  timestamp?: Date;
}

export interface TransitOpenings {
  [opening_id: string]: TransitOpening;
}

export const useTransits = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Transit[], AxiosError>({
    queryKey: ['transits'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Transit[]>(`${api_base_url}/transits`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    },
  });
};

export const useCustomerAssetTransits = (
  customerId?: string,
  assetId?: string,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Transit[], AxiosError>({
    queryKey: [
      'customers',
      customerId ?? '',
      'assets',
      assetId ?? '',
      'transits',
    ],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Transit[]>(
        `${api_base_url}/customers/${customerId ?? ''}/assets/${
          assetId ?? ''
        }/transits`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    },
    enabled: !!customerId && !!assetId,
  });
};

export const useCustomerAssetTransit = (
  customerId?: string,
  assetId?: string,
  transitId?: string,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Transit, AxiosError>({
    queryKey: [
      'customers',
      customerId ?? '',
      'assets',
      assetId ?? '',
      'transits',
      transitId ?? '',
    ],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Transit>(
        `${api_base_url}/customers/${customerId ?? ''}/assets/${
          assetId ?? ''
        }/transits/${transitId ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return response.data;
    },
    refetchInterval: 5000,
    enabled: !!customerId && !!assetId && !!transitId,
  });
};
