import { RoxtecLogo } from '@/components/Icon';
import { NavTarget } from '@/routes';
import { NavLink } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="flex grow flex-col items-center gap-8 p-8">
      <RoxtecLogo />
      <h1 className="text-xl">Page not found</h1>
      <p className="text-center text-gray-500">
        Sorry, we couldn&apos;t find the page you&apos;re looking for.
      </p>
      <NavLink to={NavTarget.Home} className="text-roxtec underline">
        Go home
      </NavLink>
    </div>
  );
};

export default PageNotFound;
