import { ApiGuard, Guard, reviveApiGuard } from '@/api/useGuard';
import { useConfig } from '@/useConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useMemo } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { useCustomerAssetTransit } from '@/api/useTransits';

export interface GuardEvent {
  type: 'added' | 'modified' | 'removed';
  guard: ApiGuard;
}

interface TransitGuardsStore {
  guards: Record<string, Guard>;
  setGuard: (guard: Guard) => void;
  removeGuard: (id: string) => void;
}

export const useTransitGuards = create<TransitGuardsStore>()(
  devtools(
    (set) => ({
      guards: {},
      setGuard: (guard: Guard) =>
        set((state) => ({ guards: { ...state.guards, [guard.id]: guard } })),
      removeGuard: (id: string) =>
        set(
          produce((state) => {
            delete state.guards[id];
          }),
        ),
    }),
    { name: 'transit-guards' },
  ),
);

export const useTransitSubscription = (
  customer_id?: string,
  asset_id?: string,
  transit_id?: string,
) => {
  const { api_base_url } = useConfig();
  const { getAccessTokenSilently } = useAuth0();
  const { data: transit } = useCustomerAssetTransit(
    customer_id,
    asset_id,
    transit_id,
  );

  const { setGuard, removeGuard } = useTransitGuards(
    useCallback(
      ({ setGuard, removeGuard }) => ({
        setGuard,
        removeGuard,
      }),
      [],
    ),
  );

  const websocketUrl = useMemo(
    () => api_base_url.replace('http', 'ws') + '/notifications',
    [api_base_url],
  );

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    websocketUrl,
    {
      shouldReconnect: () => true,
      reconnectInterval: 5000,
    },
  );

  useEffect(() => {
    if (!lastMessage?.data) {
      return;
    }

    const event: GuardEvent = JSON.parse(lastMessage.data as string);
    const guard: Guard = reviveApiGuard(event.guard);

    switch (event.type) {
      case 'removed':
        removeGuard(guard.id);
        break;
      case 'modified':
      case 'added':
        if (!guard.placement) {
          removeGuard(guard.id);
        } else {
          setGuard(guard);
        }
        break;
    }
  }, [lastMessage, removeGuard, setGuard]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN || !transit_id) {
      return;
    }

    void getAccessTokenSilently().then((accessToken) => {
      sendJsonMessage({
        type: 'authenticate',
        access_token: accessToken,
      });
      sendJsonMessage({
        type: 'subscribe',
        target: {
          type: 'transit',
          id: transit_id,
        },
      });
    });
  }, [getAccessTokenSilently, readyState, sendJsonMessage, transit_id]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN || !transit_id || !transit?.openings) {
      return;
    }

    Object.values(transit.openings).forEach((opening) => {
      if (!opening.guard_id) {
        return;
      }

      sendJsonMessage({
        type: 'subscribe',
        target: {
          type: 'guard',
          id: opening.guard_id,
        },
      });
    });
  }, [readyState, sendJsonMessage, transit?.openings, transit_id]);
};
