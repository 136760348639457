import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { create } from 'zustand';
import { useCallback } from 'react';
import { devtools } from 'zustand/middleware';

interface Config {
  auth0: {
    domain: string;
    clientID: string;
    redirectUri: string;
    responseType: string;
    audience: string;
  };
  api_base_url: string;
}

const defaultConfig: Config = {
  api_base_url: 'https://dev.guard-api.roxtec.com',
  auth0: {
    domain: 'roxtec.eu.auth0.com',
    clientID: '0KtdgagppVpq6mmBcWRTAUajn6lsTJMq',
    redirectUri: 'http://localhost:3000/callback',
    responseType: 'token id_token',
    audience: 'https://dev.rto.roxtec.com/api',
  },
};

interface ConfigResponse {
  auth0: Partial<Config['auth0']>;
  api_base_url?: string;
}

interface ConfigStore extends Config {
  loaded: boolean;
  setConfig: (config: ConfigResponse) => void;
}

export function mergeState(a: Config, b: ConfigResponse): Config {
  return { ...a, ...b, auth0: { ...a.auth0, ...b.auth0 } };
}

export const useConfig = create<ConfigStore>()(
  devtools(
    (set) => ({
      ...defaultConfig,
      loaded: false,
      setConfig: (partialConfig) => {
        set((state) => ({ ...mergeState(state, partialConfig), loaded: true }));
      },
    }),
    { name: 'config' },
  ),
);

export const useConfigQuery = () => {
  const setConfig = useConfig(useCallback(({ setConfig }) => setConfig, []));

  return useQuery<ConfigResponse, AxiosError>({
    queryKey: ['config'],
    queryFn: async () => {
      const response = await axios.get<ConfigResponse>('/config/config.json');
      setConfig(response.data);
      return response.data;
    },
  });
};
