import { useConfig } from '@/useConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Guard, reviveApiGuard } from '@/api/useGuard';
import { GuardEvent } from '@/api/useTransitGuards';

export const useGuardSubscription = (guardId?: string) => {
  const { api_base_url } = useConfig();
  const { getAccessTokenSilently } = useAuth0();
  const [guard, setGuard] = useState<Guard | null>(null);

  const websocketUrl = useMemo(
    () => api_base_url.replace('http', 'ws') + '/notifications',
    [api_base_url],
  );

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    websocketUrl,
    {
      shouldReconnect: () => true,
      reconnectInterval: 5000,
    },
  );

  useEffect(() => {
    if (!lastMessage?.data) {
      return;
    }

    const event: GuardEvent = JSON.parse(lastMessage.data as string);
    const guard: Guard = reviveApiGuard(event.guard);

    switch (event.type) {
      case 'removed':
        setGuard(null);
        break;
      case 'modified':
      case 'added':
        setGuard(guard);
        break;
    }
  }, [lastMessage, setGuard]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN || !guardId) {
      return;
    }

    void getAccessTokenSilently().then((accessToken) => {
      sendJsonMessage({
        type: 'authenticate',
        access_token: accessToken,
      });
      sendJsonMessage({
        type: 'subscribe',
        target: {
          type: 'guard',
          id: guardId,
        },
      });
    });
  }, [getAccessTokenSilently, readyState, sendJsonMessage, guardId]);

  return { guard, isConnected: readyState === ReadyState.OPEN };
};
