import React from 'react';
import { classNames } from '@/classNames';

interface Props {
  name: string;
  options: string[];
  value: string;
  onChange: (value: string) => void;
}

const RadioOptions: React.FC<Props> = ({ name, value, onChange, options }) => {
  if (options.length === 0) return null;

  return (
    <fieldset className="flex flex-col">
      {options.map((option) => (
        <div
          key={option}
          className="flex items-center border-b border-gray-200 p-4"
        >
          <input
            id={option}
            name={name}
            type="radio"
            defaultChecked={value === option}
            className={classNames(
              'h-5 w-5 border-gray-300 text-roxtec-500 focus:ring-roxtec-500',
              value === option && 'font-bold',
            )}
            onChange={() => onChange(option)}
          />
          <label
            htmlFor={option}
            className="ml-3 block grow text-sm font-medium capitalize leading-6 text-gray-900"
          >
            {option}
          </label>
        </div>
      ))}
    </fieldset>
  );
};

export default RadioOptions;
