import Navbar from '@/components/Navbar';
import { RoxtecLogo } from '@/components/Icon';
import Search from '@/components/Search';
import React, { useEffect, useMemo, useState } from 'react';
import { MdBusiness, MdKeyboardArrowRight } from 'react-icons/md';
import { Customer, useCustomers } from '@/api/useCustomers';
import Fuse from 'fuse.js';
import SortButton, { Sort, sortEntities } from '@/components/List/SortButton';
import { NavTarget } from '@/routes';
import ListItem from '@/components/List/ListItem';
import { Link } from 'react-router-dom';
import List from '@/components/List/List';
import FuseResult = Fuse.FuseResult;

const sortKeys: (keyof Customer)[] = ['name', 'consortium'];

const BrowseCustomers: React.FC = () => {
  const [sort, setSort] = useState<Sort<Customer>>({
    key: 'name',
    direction: 'ascending',
  });

  const { data: customers, isLoading, isFetched } = useCustomers();
  const [search, setSearch] = useState('');

  const fuse = useMemo(() => new Fuse<Customer>([], { keys: sortKeys }), []);
  useEffect(() => {
    if (customers) {
      fuse.setCollection(customers);
    }
  }, [fuse, customers]);

  const filteredCustomers = useMemo<Customer[]>(() => {
    if (!search) {
      if (!customers) return [];

      return sortEntities(customers, sort);
    }
    return fuse.search(search).map((r: FuseResult<Customer>) => r.item);
  }, [customers, fuse, search, sort]);

  return (
    <div className="flex h-full max-h-full flex-col">
      <Navbar back={NavTarget.Home} icon={<RoxtecLogo />} />
      <div className="flex shrink flex-col bg-white">
        <div className="m-4 flex items-center gap-2">
          <Search value={search} onChange={setSearch} className="grow" />
          <SortButton sort={sort} onChange={setSort} keys={sortKeys} />
        </div>
        <h2 className="header2 border-b-4 border-roxtec px-4 pb-2">
          Customers
        </h2>
      </div>
      <List
        emptyMessage="No customers found"
        isLoading={isLoading}
        isFetched={isFetched}
      >
        {filteredCustomers.map((customer) => (
          <Link key={customer.id} to={NavTarget.CustomerAssets(customer.id)}>
            <ListItem
              title={customer.name}
              subtitle={customer.consortium}
              startIcon={<MdBusiness className="h-6 w-6" />}
              endIcon={<MdKeyboardArrowRight className="h-8 w-8" />}
            />
          </Link>
        ))}
      </List>
    </div>
  );
};

export default BrowseCustomers;
