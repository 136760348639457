import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfig } from '@/useConfig';
import axios, { AxiosError } from 'axios';

export interface Asset {
  id: string;
  customer_id: string;
  name: string;
}

export const useAssets = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Asset[], AxiosError>({
    queryKey: ['assets'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Asset[]>(`${api_base_url}/assets`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    },
  });
};

export const useCustomerAssets = (customerId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Asset[], AxiosError>({
    queryKey: ['customers', customerId, 'assets'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Asset[]>(
        `${api_base_url}/customers/${customerId ?? ''}/assets`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    },
    enabled: !!customerId,
  });
};

export const useCustomerAsset = (customerId?: string, assetId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Asset, AxiosError>({
    queryKey: ['customers', customerId, 'assets', assetId],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Asset>(
        `${api_base_url}/customers/${customerId ?? ''}/assets/${assetId ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    },
    enabled: !!customerId && !!assetId,
  });
};
