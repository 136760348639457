import React, { ErrorInfo } from 'react';
import ErrorDialog from './ErrorDialog';

interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorDialog
          header={'Error'}
          message={'An error has occured.'}
          onClose={() => {
            window.location.href = '/';
          }}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
