import { Transition } from '@headlessui/react';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import StepSelectGuard from '@/components/Wizard/StepSelectGuard';
import StepLinkGuardStatus from '@/components/Wizard/StepLinkGuardStatus';
import { useGuardMutations } from '@/api/useGuard';
import StepLinkGuardComplete from '@/components/Wizard/StepLinkGuardComplete';

export interface InstallWizardProps {
  customerId?: string;
  assetId?: string;
  transitId?: string;
  opening?: string;
  onClose: () => void;
}

const InstallWizard: React.FC<InstallWizardProps> = ({
  customerId,
  assetId,
  transitId,
  opening,
  onClose,
}) => {
  const open = useMemo(() => !!opening, [opening]);
  const [step, setStep] = useState(1);
  const [guardId, setGuardId] = useState<string>('');
  const { linkGuard, removeGuard } = useGuardMutations();

  useEffect(() => {
    if (!open) {
      setStep(1);
      setGuardId('');
    }
  }, [open]);

  const handleLinkGuard = useCallback(
    (id: string) => {
      if (!id || !customerId || !assetId || !transitId || !opening) {
        return;
      }

      setStep(2);
      void linkGuard.mutateAsync({
        id,
        status: 'C',
        placement: {
          customer_id: customerId,
          asset_id: assetId,
          transit_id: transitId,
          opening_id: opening,
        },
      });
    },
    [assetId, customerId, linkGuard, opening, transitId],
  );

  const handleGuardLinked = useCallback(() => {
    setStep(3);
  }, []);

  const handleUnlinkGuard = useCallback(
    (id: string) => {
      void removeGuard.mutateAsync(id);
      onClose();
    },
    [onClose, removeGuard],
  );

  if (!customerId || !assetId || !transitId) {
    return null;
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <div>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 z-30 transition-all"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="absolute left-0 top-0 h-[59px] w-[60px] bg-white" />
            <div className="absolute right-0 top-0 h-[59px] w-[60px] bg-white" />
          </div>
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="transform translate-y-full"
          enterTo="transform translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="transform translate-y-0"
          leaveTo="transform translate-y-full"
        >
          <div className="fixed inset-x-0 bottom-0 top-[60px] z-40 flex flex-col bg-white shadow-lg">
            {step === 1 && (
              <StepSelectGuard
                guardId={guardId}
                onGuardIdChange={setGuardId}
                onCancel={onClose}
                onNext={handleLinkGuard}
              />
            )}
            {step === 2 && (
              <StepLinkGuardStatus
                guardId={guardId}
                opening={opening}
                onGuardLinked={handleGuardLinked}
                onCancel={handleUnlinkGuard}
              />
            )}
            {step === 3 && (
              <StepLinkGuardComplete guardId={guardId} onClose={onClose} />
            )}
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

export default InstallWizard;
