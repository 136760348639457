import React, { useCallback, useEffect, useState } from 'react';

import { CameraSwitch } from './Icon/CameraSwitch';
import { CameraMissing } from './Icon/CameraMissing';
import { DecodeHintType, useZxing } from 'react-zxing';
import { useError } from '@/useError';
import { BarcodeFormat } from '@zxing/library';

interface Props {
  onScan: (result: string) => void;
  onCancel: () => void;
}

const hints = new Map<DecodeHintType, any>();
const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX];
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

const Scanner: React.FC<Props> = ({ onCancel, onScan }) => {
  const { setError } = useError();

  const [deviceId, setDeviceId] = useState('');
  const [available, setAvailable] = useState<string[]>([]);

  useEffect(() => {
    void navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: {
            ideal: 'environment',
          },
        },
      })
      .then((stream) => {
        const videoTracks = stream.getVideoTracks();
        const settings = videoTracks[0].getSettings();
        setDeviceId(settings.deviceId || '');
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log('camera error');
      setError({
        message:
          'Ensure a camera is available and permission to use camera has been given.',
        header: 'Camera error',
      });
      return;
    }

    void navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        return devices
          .filter((device) => device.kind === 'videoinput' && !!device.deviceId)
          .map((device) => device.deviceId);
      })
      .then(setAvailable);
  }, [setError]);

  const handleSwitchCamera = useCallback(() => {
    if (!available || available.length === 0) return;

    const currentIndex = available.findIndex((id) => id === deviceId);

    if (currentIndex === -1) {
      setDeviceId(available[0]);
      return;
    }

    const nextIndex = (currentIndex + 1) % available.length;
    setDeviceId(available[nextIndex]);
  }, [available, deviceId]);

  const { ref } = useZxing({
    paused: !deviceId,
    deviceId,
    hints,
    onResult(result) {
      onScan(result.getText());
    },
  });

  return (
    <div className="grow flex flex-col bg-gray-700 text-white">
      <div className="flex grow flex-col items-center justify-center gap-4">
        {deviceId && <video ref={ref} className="grow" />}
        {!deviceId && (
          <>
            <CameraMissing />
            <p className="text-center">
              Ensure a camera is available and permission to use camera has been
              given.
            </p>
          </>
        )}
      </div>
      <div className="h-22 flex justify-between gap-4 bg-gray-900 p-4">
        <button
          type="button"
          className="button grow justify-center"
          onClick={onCancel}
        >
          Cancel
        </button>
        {available.length >= 2 && (
          <button
            type="button"
            className="button p-2"
            onClick={handleSwitchCamera}
            disabled={available.length < 2}
          >
            <CameraSwitch />
          </button>
        )}
      </div>
    </div>
  );
};

export default Scanner;
