import React from 'react';

export const TransitIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg width="100px" height="100px" viewBox="0 0 100 100" className={className}>
    <g
      id="Transit"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect
        id="Rectangle"
        fill="#333F46"
        x="20"
        y="0"
        width="60"
        height="100"
      />
      <rect
        id="Rectangle"
        fill="#FFFFFF"
        x="28"
        y="16"
        width="44"
        height="76"
      />
    </g>
  </svg>
);
