import { TransitOpening } from '@/api/useTransits';
import { cva } from 'class-variance-authority';
import { Guard, GuardStatus } from '@/api/useGuard';

export const gridItem = cva(
  'flex h-[100px] min-w-[62px] max-w-[80px] flex-col items-center justify-center self-center rounded p-2 text-center text-gray-800 shadow',
  {
    variants: {
      status: {
        NI: 'bg-status-NI',
        XI: 'bg-status-XI animate-blink',
        XP: 'bg-status-XP animate-blink',
        M1: 'bg-status-M1',
        M2: 'bg-status-M2',
        GA: 'bg-status-GA animate-blink',
        P1: 'bg-status-P1',
        P2: 'bg-status-P2',
        P3: 'bg-status-P3',
        C: 'bg-status-C',
        '-': 'bg-gray-300',
      } as Record<GuardStatus, string>,
    },
    defaultVariants: {
      status: '-',
    },
  },
);

interface Props {
  id: string;
  opening?: TransitOpening;
  guard?: Guard;
  onClick: (id: string) => void;
}

const OpeningGridItem: React.FC<Props> = ({ id, opening, guard, onClick }) => {
  if (!opening) {
    return null;
  }

  return (
    <button
      className={gridItem({ status: guard?.status ?? opening.status })}
      onClick={() => onClick(id)}
    >
      <div className="text-lg font-bold">{id}</div>
    </button>
  );
};

export default OpeningGridItem;
