import { useGuardSubscription } from '@/api/useGuardSubscription';
import React from 'react';
import { MdCheck } from 'react-icons/md';

interface Props {
  guardId: string;
  onClose: () => void;
}

const StepLinkGuardComplete: React.FC<Props> = ({ guardId, onClose }) => {
  const { guard } = useGuardSubscription(guardId);

  return (
    <>
      <div className="flex grow flex-col gap-4 px-4">
        <MdCheck className="h-56 w-full text-roxtec" />
        <ul className="flex w-full flex-col divide-y-2 divide-gray-200 border-2 border-gray-200">
          <li className="flex items-center justify-between p-4 ">
            <span className="text-gray-500">Opening</span>
            <span className="font-bold text-gray-800">
              {guard?.placement?.opening_id}
            </span>
          </li>
          <li className="flex items-center justify-between p-4 ">
            <span className="text-gray-500">Guard ID</span>
            <span className="font-bold text-gray-800">{guardId}</span>
          </li>
          <li className="flex items-center justify-between p-4 ">
            <span className="text-gray-500">Status</span>
            <span className="font-bold text-gray-800">
              {guard?.status ?? '-'}
            </span>
          </li>
          <li className="flex items-center justify-between p-4 ">
            <span className="text-gray-500">Last seen</span>
            <span className="font-bold text-gray-800">
              {guard?.timestamp.toFormat('yyyy-MM-dd HH:mm:ss') ?? '-'}
            </span>
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-4 p-4">
        <button
          type="button"
          onClick={onClose}
          className="navbar-button"
          disabled={false}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default StepLinkGuardComplete;
