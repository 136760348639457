import React from 'react';

export const NoGuardIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg width="54px" height="66px" viewBox="0 0 54 66" className={className}>
    <defs>
      <rect id="PlusBar" width="30" height="4" fill="currentColor" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill="none"
        stroke="currentColor"
        d="M27,65 L35,61 C46.1452444,55.1621298 53,43.4494884 53,31 L53,11 C53,10.665096 52.6930536,10.2057891 52,10 L27,1 L2,10 C1.30694637,10.2027304 1,10.6650499 1,11 L1,31 C1,43.7870525 7.8550637,55.0837393 19,61 L27,65 Z"
        strokeWidth="2"
        strokeDasharray="4"
      />
      <use href="#PlusBar" x="12" y="28" width="30" height="4" />
      <use href="#PlusBar" transform="rotate(-90 15 2)" x="-28" y="12" />
    </g>
  </svg>
);
