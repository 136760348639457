import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router';
import { NavTarget } from '@/routes';

const Callback = () => {
  const { isAuthenticated, error } = useAuth0();

  if (error) {
    return <p>An error occured when setting up the session: {error.message}</p>;
  }

  if (isAuthenticated) {
    return <Navigate to={NavTarget.Home} />;
  }

  return <p>Setting up session...</p>;
};

export default Callback;
