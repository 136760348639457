import { useNavigate } from 'react-router-dom';
import Navbar from '@/components/Navbar';
import { RoxtecLogo } from '@/components/Icon';

const Logout: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex grow flex-col">
      <Navbar icon={<RoxtecLogo />} />
      <p className="grow p-4 text-center text-lg">You have been logged out</p>
      <button
        type="button"
        onClick={() => {
          navigate('/');
        }}
        className="navbar-button m-4 bg-roxtec"
      >
        Log in
      </button>
    </div>
  );
};

export default Logout;
