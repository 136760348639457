import React, { Fragment, MouseEventHandler, useCallback } from 'react';
import { Transition } from '@headlessui/react';
import { MdClose } from 'react-icons/md';

interface Props {
  open: boolean;
  onClose: () => void;
}

const ConfirmUpdateAlert: React.FC<Props> = ({ open, onClose }) => {
  const handleClose = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      if (!open) return;
      e.stopPropagation();
      onClose();
    },
    [onClose, open],
  );

  const handleUpdate = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <div>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 z-[9998] bg-black/25 transition-all"
              onClick={handleClose}
            />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="transform translate-y-full"
            enterTo="transform translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="transform translate-y-0"
            leaveTo="transform translate-y-full"
          >
            <div className="fixed inset-x-0 bottom-0 z-[9999] flex flex-col bg-white shadow-lg">
              <div className="flex justify-end p-2">
                <button type="button" onClick={handleClose}>
                  <MdClose className="h-8 w-8" />
                </button>
              </div>
              <div className="flex flex-col items-center justify-center gap-4 px-5 py-4">
                <h1 className="header2 text-center">New version available</h1>
                <p className="text-center">Press Ok to reload</p>
              </div>
              <div className="flex flex-col gap-4 p-4">
                <button
                  type="button"
                  onClick={handleUpdate}
                  className="navbar-button"
                >
                  Ok
                </button>
                <button
                  type="button"
                  onClick={handleClose}
                  className="outline-button py-6"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </>
  );
};

export default ConfirmUpdateAlert;
