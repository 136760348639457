import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfig } from '@/useConfig';
import axios, { AxiosError } from 'axios';

export interface Customer {
  id: string;
  name: string;
  consortium: string;
}

export const useCustomers = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Customer[], AxiosError>({
    queryKey: ['customers'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Customer[]>(
        `${api_base_url}/customers`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    },
  });
};

export const useCustomer = (id?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api_base_url } = useConfig();

  return useQuery<Customer, AxiosError>({
    queryKey: ['customers', id],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get<Customer>(
        `${api_base_url}/customers/${id ?? ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return response.data;
    },
    enabled: !!id,
  });
};
