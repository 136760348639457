import { useParams } from 'react-router-dom';
import { useCustomer } from '@/api/useCustomers';
import { useCustomerAsset } from '@/api/useAssets';
import { useCustomerAssetTransit } from '@/api/useTransits';
import ListItem from '@/components/List/ListItem';
import React from 'react';
import ErrorMessage from '@/components/ErrorMessage';

const TransitInformation = () => {
  const { customer_id, asset_id, transit_id } = useParams<{
    customer_id?: string;
    asset_id?: string;
    transit_id?: string;
  }>();

  const {
    data: customer,
    isLoading: isCustomerLoading,
    error: customerError,
  } = useCustomer(customer_id);
  const {
    data: asset,
    isLoading: isAssetLoading,
    error: assetError,
  } = useCustomerAsset(customer_id, asset_id);
  const {
    data: transit,
    isLoading: isTransitLoading,
    error: transitError,
  } = useCustomerAssetTransit(customer_id, asset_id, transit_id);

  return (
    <>
      <ul className="flex h-full flex-col overflow-auto">
        <ListItem
          title={
            isCustomerLoading ? (
              <span className="block h-6 w-64 animate-pulse rounded-lg bg-gray-200" />
            ) : (
              customer?.name ?? customer_id
            )
          }
          subtitle="Customer"
        />
        <ListItem
          title={
            isAssetLoading ? (
              <span className="block h-6 w-64 animate-pulse rounded-lg bg-gray-200" />
            ) : (
              asset?.name ?? asset_id
            )
          }
          subtitle="Asset"
        />
        <ListItem
          title={
            isTransitLoading ? (
              <span className="block h-6 w-64 animate-pulse rounded-lg bg-gray-200" />
            ) : (
              transit?.location ?? (
                <span className="font-medium italic text-gray-300">
                  Missing
                </span>
              )
            )
          }
          subtitle="Location"
        />
      </ul>
      {customerError && (
        <ErrorMessage title="Failed to load customer" error={customerError} />
      )}
      {assetError && (
        <ErrorMessage title="Failed to load asset" error={assetError} />
      )}
      {transitError && (
        <ErrorMessage title="Failed to load transit" error={transitError} />
      )}
    </>
  );
};

export default TransitInformation;
