import React, { useEffect } from 'react';
import Spinner from '@/components/Spinner';
import { useGuardSubscription } from '@/api/useGuardSubscription';

interface Props {
  guardId: string;
  opening?: string;
  onGuardLinked: () => void;
  onCancel: (id: string) => void;
}

const StepLinkGuardStatus: React.FC<Props> = ({
  guardId,
  opening,
  onGuardLinked,
  onCancel,
}) => {
  const { guard } = useGuardSubscription(guardId);

  useEffect(() => {
    if (guard?.status === 'C') {
      onGuardLinked();
    }
  }, [guard?.status, onGuardLinked]);

  if (!guard || !opening) return null;

  return (
    <>
      <div className="flex grow flex-col gap-4 px-4">
        <div
          className="h-56 w-full bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(/images/installation_animation.gif)`,
          }}
        />
        <ul className="flex w-full flex-col divide-y-2 divide-gray-200 border-2 border-gray-200">
          <li className="flex items-center justify-between p-4 ">
            <span className="text-gray-500">Opening</span>
            <span className="font-bold text-gray-800">{opening}</span>
          </li>
          <li className="flex items-center justify-between p-4 ">
            <span className="text-gray-500">Guard ID</span>
            <span className="font-bold text-gray-800">{guardId}</span>
          </li>
          <li className="flex items-center justify-between p-4 ">
            <span className="text-gray-500">Status</span>
            <span className="font-bold text-gray-800">
              {guard?.status || '-'}
            </span>
          </li>
        </ul>
        <div className="flex items-center gap-4">
          <Spinner className="h-16 w-16 text-roxtec" />
          <p className="grow">
            Waiting for the clip and module to be installed and contact the
            gateway...
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-4">
        <button
          type="button"
          onClick={() => onCancel(guardId)}
          className="outline-button"
          disabled={false}
        >
          Cancel installation
        </button>
      </div>
    </>
  );
};

export default StepLinkGuardStatus;
