import React from 'react';

interface Props {
  title: string;
  error: Error;
}

const ErrorMessage: React.FC<Props> = ({ title, error }) => {
  return (
    <div className="flex grow flex-col items-center justify-center gap-2 p-4">
      <h2 className="text-center">{title}</h2>
      {error.message && (
        <p className="text-sm text-gray-400">{error.message}</p>
      )}
    </div>
  );
};

export default ErrorMessage;
