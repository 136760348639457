import { TransitOpening } from '@/api/useTransits';
import { NoGuardIcon, ShieldIcon } from '@/components/Icon';
import { Guard } from '@/api/useGuard';

interface Props {
  id: string;
  opening?: TransitOpening;
  guard?: Guard;
  onClick: (id: string) => void;
}

const OpeningListItem: React.FC<Props> = ({ id, opening, guard, onClick }) => {
  if (!opening) {
    return null;
  }

  return (
    <li className="">
      <button
        type="button"
        className="grid h-[60px] w-full grid-cols-[46px_46px_1fr] items-center justify-center gap-2 border-b border-gray-200 px-4 py-2"
        onClick={() => onClick(id)}
      >
        <div className="flex items-center justify-center">
          <span className="font-bold">{id}</span>
        </div>
        <div className="flex items-center justify-center">
          {guard ? (
            <ShieldIcon
              status={guard?.status ?? opening.status}
              className="h-8 w-8"
            />
          ) : (
            <NoGuardIcon className="h-8 w-8 text-gray-400" />
          )}
        </div>
        <div className="flex grow flex-col items-start">
          <span className="text-sm text-gray-500">
            {guard ? 'Guard status' : 'Add guard'}
          </span>
          <span className="font-bold text-gray-700">
            {guard?.status ?? opening.status}
          </span>
        </div>
      </button>
    </li>
  );
};

export default OpeningListItem;
