import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import SortButton, { Sort, sortEntities } from '@/components/List/SortButton';
import { Asset, useCustomerAssets } from '@/api/useAssets';
import Fuse from 'fuse.js';
import Navbar from '@/components/Navbar';
import Search from '@/components/Search';
import { NavTarget } from '@/routes';
import { useCustomers } from '@/api/useCustomers';
import { AssetIcon } from '@/components/Icon';
import ListItem from '@/components/List/ListItem';
import { MdKeyboardArrowRight } from 'react-icons/md';
import ErrorMessage from '@/components/ErrorMessage';
import List from '@/components/List/List';
import FuseResult = Fuse.FuseResult;

const sortKeys: (keyof Asset)[] = ['name'];

const CustomerAssets: React.FC = () => {
  const { id: customerId } = useParams<{ id?: string }>();

  const { data: customers, isLoading: isCustomersLoading } = useCustomers();

  const {
    data: assets,
    isLoading: isAssetsLoading,
    isFetched,
    error: assetError,
  } = useCustomerAssets(customerId);

  const isLoading = useMemo(
    () => isCustomersLoading || isAssetsLoading,
    [isCustomersLoading, isAssetsLoading],
  );

  const customer = useMemo(
    () => customers?.find((customer) => customer.id === customerId),
    [customerId, customers],
  );

  const [sort, setSort] = useState<Sort<Asset>>({
    key: 'name',
    direction: 'ascending',
  });

  const [search, setSearch] = useState('');
  const fuse = useMemo(() => new Fuse<Asset>([], { keys: sortKeys }), []);
  useEffect(() => {
    if (assets) {
      fuse.setCollection(assets);
    }
  }, [assets, fuse]);

  const filteredAssets = useMemo<Asset[]>(() => {
    if (!search) {
      if (!assets) return [];

      return sortEntities(assets, sort);
    }

    return fuse.search(search).map((r: FuseResult<Asset>) => r.item);
  }, [assets, fuse, search, sort]);

  return (
    <div className="flex h-full max-h-full flex-col">
      <Navbar back={NavTarget.Customers} title={customer?.name} />
      <div className="flex shrink flex-col bg-white">
        <div className="m-4 flex items-center gap-2">
          <Search value={search} onChange={setSearch} className="grow" />
          <SortButton sort={sort} onChange={setSort} keys={sortKeys} />
        </div>
        <h2 className="header2 border-b-4 border-roxtec px-4 pb-2">Assets</h2>
      </div>
      {assetError && (
        <ErrorMessage title="Error loading assets" error={assetError} />
      )}
      {!assetError && (
        <List
          emptyMessage="No assets found"
          isLoading={isLoading}
          isFetched={isFetched}
        >
          {filteredAssets.map((asset) => (
            <Link
              key={asset.id}
              to={NavTarget.CustomerAssetTransits(customerId, asset.id)}
            >
              <ListItem
                title={asset.name}
                startIcon={<AssetIcon className="h-6 w-6" />}
                endIcon={<MdKeyboardArrowRight className="h-8 w-8" />}
              />
            </Link>
          ))}
        </List>
      )}
    </div>
  );
};

export default CustomerAssets;
