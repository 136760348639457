import React from 'react';

interface Props {
  header: string;
  message: string;
  onClose: () => void;
}

const ErrorDialog: React.FC<Props> = ({ message, header, onClose }) => (
  <div className="fixed inset-0 z-[9999999] bg-black/20 p-5">
    <div className="border-2 border-error bg-white p-5 shadow">
      <p className="dialog-header mb-2">{header}</p>
      <p className="text-lg">{message}</p>
      <button
        onClick={onClose}
        className="mt-7 h-16 w-full bg-error text-white"
      >
        Close
      </button>
    </div>
  </div>
);

export default ErrorDialog;
