import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCustomerAsset } from '@/api/useAssets';
import { Transit, useCustomerAssetTransits } from '@/api/useTransits';
import SortButton, { Sort, sortEntities } from '@/components/List/SortButton';
import Fuse from 'fuse.js';
import Navbar from '@/components/Navbar';
import Search from '@/components/Search';
import ListItem from '@/components/List/ListItem';
import { NavTarget } from '@/routes';
import { TransitIcon } from '@/components/Icon';
import { MdKeyboardArrowRight } from 'react-icons/md';
import ErrorMessage from '@/components/ErrorMessage';
import List from '@/components/List/List';
import FuseResult = Fuse.FuseResult;

const sortKeys: (keyof Transit)[] = ['name', 'location'];

const CustomerAssetTransits: React.FC = () => {
  const { customer_id, asset_id } = useParams<{
    customer_id?: string;
    asset_id?: string;
  }>();

  const { data: asset, isLoading: isAssetLoading } = useCustomerAsset(
    customer_id,
    asset_id,
  );
  const {
    data: transits,
    isLoading: isTransitsLoading,
    isFetched,
    error: transitError,
  } = useCustomerAssetTransits(customer_id, asset_id);

  const isLoading = useMemo(
    () => isAssetLoading || isTransitsLoading,
    [isAssetLoading, isTransitsLoading],
  );

  const [sort, setSort] = useState<Sort<Transit>>({
    key: 'name',
    direction: 'ascending',
  });

  const [search, setSearch] = useState('');
  const fuse = useMemo(() => new Fuse<Transit>([], { keys: sortKeys }), []);
  useEffect(() => {
    if (transits) {
      fuse.setCollection(transits);
    }
  }, [fuse, transits]);

  const filteredTransits = useMemo<Transit[]>(() => {
    if (!search) {
      if (!transits) return [];

      return sortEntities(transits ?? [], sort);
    }
    return fuse.search(search).map((r: FuseResult<Transit>) => r.item);
  }, [search, fuse, transits, sort]);

  return (
    <div className="flex h-full max-h-full flex-col">
      <Navbar
        back={NavTarget.CustomerAssets(customer_id)}
        title={asset?.name ?? asset_id}
      />
      <div className="flex shrink flex-col bg-white">
        <div className="m-4 flex items-center gap-2">
          <Search value={search} onChange={setSearch} className="grow" />
          <SortButton sort={sort} onChange={setSort} keys={sortKeys} />
        </div>
        <h2 className="header2 border-b-4 border-roxtec px-4 pb-2">Transits</h2>
      </div>
      {transitError && (
        <ErrorMessage
          title="Error loading asset transits"
          error={transitError}
        />
      )}
      {!transitError && (
        <List
          emptyMessage="No transits found"
          isLoading={isLoading}
          isFetched={isFetched}
        >
          {filteredTransits.map((transit) => (
            <Link
              key={transit.id}
              to={NavTarget.CustomerAssetTransit(
                transit.customer_id,
                transit.asset_id,
                transit.id,
              )}
            >
              <ListItem
                title={transit.name}
                subtitle={transit.location}
                startIcon={<TransitIcon className="h-6 w-6" />}
                endIcon={<MdKeyboardArrowRight className="h-8 w-8" />}
              />
            </Link>
          ))}
        </List>
      )}
    </div>
  );
};

export default CustomerAssetTransits;
