import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router';
import { NavTarget } from '@/routes';

const Root: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  if (!isAuthenticated) {
    if (!isLoading) {
      void loginWithRedirect();
    }
    return <p>Logging in...</p>;
  }

  return <Navigate to={NavTarget.TransitFinder} replace />;
};

export default Root;
