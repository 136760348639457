export const NavTarget = {
  Home: '/',
  Callback: '/callback',
  Logout: '/logout',
  Customers: '/customers',
  CustomerAssets: (id = ':id') => `/customers/${id}`,
  CustomerAssetTransits: (customerId = ':customer_id', assetId = ':asset_id') =>
    `/customers/${customerId}/assets/${assetId}`,
  CustomerAssetTransit: (
    customerId = ':customer_id',
    assetId = ':asset_id',
    transitId = ':transit_id',
  ) => `/customers/${customerId}/assets/${assetId}/transits/${transitId}`,
  TransitFinder: '/transit-finder',
};
