import { useParams } from 'react-router-dom';
import { useCustomerAssetTransit } from '@/api/useTransits';
import { useCallback, useMemo, useState } from 'react';
import ErrorMessage from '@/components/ErrorMessage';
import OpeningListItem from '@/components/Transit/OpeningListItem';
import OpeningGridItem from '@/components/Transit/OpeningGridItem';
import GuardInfoDialog from '@/components/Transit/GuardInfoDialog';
import { Guard } from '@/api/useGuard';
import { useTransitGuards } from '@/api/useTransitGuards';
import InstallWizard from '@/components/Wizard/InstallWizard';
import LoadingScreen from '@/components/LoadingScreen';

const TransitOpenings = () => {
  const { customer_id, asset_id, transit_id } = useParams<{
    customer_id?: string;
    asset_id?: string;
    transit_id?: string;
  }>();

  const {
    data: transit,
    isLoading: isTransitLoading,
    error,
  } = useCustomerAssetTransit(customer_id, asset_id, transit_id);

  const rows = useMemo(() => {
    if (!transit?.rows) return [];

    return [...Array(transit.rows).keys()].map((row) =>
      String.fromCharCode(65 + row),
    );
  }, [transit?.rows]);

  const columns = useMemo(() => {
    if (!transit?.columns) return [];

    return [...Array(transit.columns).keys()].map((col) => col + 1);
  }, [transit?.columns]);

  const openings = useMemo(() => {
    return [...rows]
      .reverse()
      .map((row) => columns.map((column) => `${row}${column}`));
  }, [columns, rows]);

  const guards = useTransitGuards(useCallback(({ guards }) => guards, []));

  const [selectedGuard, setSelectedGuard] = useState<Guard | undefined>(
    undefined,
  );
  const [installWizardOpen, setInstallWizardOpen] = useState<
    string | undefined
  >(undefined);

  const handleOpeningClick = useCallback(
    (opening: string) => {
      if (
        transit?.openings[opening].guard_id &&
        guards[transit.openings[opening].guard_id]
      ) {
        setSelectedGuard(guards[transit.openings[opening].guard_id]);
      } else {
        setInstallWizardOpen(opening);
        console.log('opening is not guarded');
      }
    },
    [guards, transit?.openings],
  );

  if (isTransitLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorMessage title="Failed to load transit" error={error} />;
  }

  if (!transit) {
    return null;
  }

  return (
    <div className="flex flex-col ">
      <GuardInfoDialog
        onClose={() => setSelectedGuard(undefined)}
        guard={selectedGuard}
      />
      <InstallWizard
        customerId={customer_id}
        assetId={asset_id}
        transitId={transit_id}
        opening={installWizardOpen}
        onClose={() => setInstallWizardOpen(undefined)}
      />
      <div className="flex w-full max-w-full items-center justify-center overflow-auto bg-gray-800">
        <div className="m-4 max-w-full">
          <div
            className="grid h-fit w-fit gap-1 rounded bg-gray-400 p-1"
            style={{
              gridTemplateColumns: `repeat(${columns.length}, 1fr)`,
            }}
          >
            {openings.map((row) =>
              row.map((opening) => (
                <OpeningGridItem
                  key={opening}
                  id={opening}
                  opening={transit.openings[opening]}
                  guard={
                    transit.openings[opening].guard_id
                      ? guards[transit.openings[opening].guard_id]
                      : undefined
                  }
                  onClick={handleOpeningClick}
                />
              )),
            )}
          </div>
        </div>
      </div>
      {rows.map((row) => (
        <div key={row}>
          <h2 className="border-b-2 border-roxtec px-4 py-2 text-lg font-bold">
            {row}
          </h2>
          <ul className="flex flex-col">
            {columns.map((column) => (
              <OpeningListItem
                key={column}
                id={`${row}${column}`}
                opening={transit.openings[`${row}${column}`]}
                guard={
                  transit.openings[`${row}${column}`].guard_id
                    ? guards[transit.openings[`${row}${column}`].guard_id]
                    : undefined
                }
                onClick={handleOpeningClick}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default TransitOpenings;
