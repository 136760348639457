import React from 'react';
import { classNames } from '@/classNames';

const Spinner: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={classNames('h-10 w-10 animate-spin text-gray-500', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12z"
      ></path>
    </svg>
  );
};

export default Spinner;
